<template>
  <div class="home">
    <div class="content">
      <h1 class="name">客户查询</h1>
      <Search
        v-model="keyLike"
        placeholder="请输入客户姓名/手机号/车牌/业务编号"
        @search="$authCheck('SCDispatchMobileH5HomeSearch')&&search()"
      />
      <Button
        class="btn"
        size="small"
        round
        type="primary"
        v-if="$authCheck('SCDispatchMobileH5HomeSearch')"
        @click="search"
        >查 询</Button
      >
      <Popup v-model="show" closeable :close-on-click-overlay="false">
        <div class="popup-title">请选择</div>
        <ul class="popup-list">
          <li
            class="popup-list-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="top">
              <div class="left">
                {{ item.customerName }} <span>{{ item.mobile }}</span>
              </div>
              <div class="right">{{ item.vehiclePlateNo }}</div>
            </div>
            <div class="bottom">
              <div class="legt">{{ item.projectCode }}</div>
              <div class="right">
                <Button size="mini" type="primary" @click="check(item)"
                  >查看</Button
                >
              </div>
            </div>
          </li>
        </ul>
      </Popup>
    </div>
  </div>
</template>

<script>
import Api from "@/api/index.js";
import { mapState } from "vuex";
import { Search, Button, Popup, Dialog, Toast } from "vant";

export default {
  name: "Home",
  components: {
    Search,
    Button,
    Popup,
  },
  computed: {
    ...mapState(["token", "info"]),
  },
  data() {
    return {
      show: false,
      list: [],
      keyLike: "",
    };
  },
  methods: {
    async search() {
      if (!this.keyLike) return Toast("请输入查询内容");
      const toast = Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      try {
        let { data, success, message } = await Api.getBasicInfo({
          keyLike: this.keyLike,
        });
        if (!success) throw message;
        if (data && data.length) {
          if (data.length > 1) {
            this.list = data.splice(0, 5);
            this.show = true;
          } else {
            this.$router.push({
              path: `/custom?projectCode=${data[0].projectCode}`,
            });
          }
        } else {
          Dialog.alert({
            title: "提示",
            message: "没有查询到，请重新输入",
            confirmButtonColor: "#5AC47F",
            confirmButtonText: "关闭",
          });
          setTimeout(() => {
            Dialog.close();
          }, 3000);
        }
        toast.clear();
      } catch (error) {
        Toast(error.message || error);
        toast.clear();
      }
    },
    check(row) {
      this.$router.push({
        path: `/custom?projectCode=${row.projectCode}`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  padding: 53px 12px;
  .content {
    height: 232px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
    text-align: center;
    .van-search {
      border-radius: 50%;
      .van-search__content {
        background-color: #f5f7f9;
        border-radius: 24px;
      }
    }
    .name {
      padding: 32px;
      height: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 16px;
    }
    .btn {
      width: 160px;
      height: 40px;
      margin-top: 20px;
      font-size: 16px;
    }
  }
}
.van-popup--center {
  width: 80%;
  min-height: 50vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  .popup-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    flex: 0 0 50px;
    border-bottom: 1px solid #e7e7e7;
  }
  .popup-list {
    overflow: auto;
    flex: 1;
    margin: 12px;
    .popup-list-item {
      padding: 12px;
      font-size: 16px;
      margin-bottom: 12px;
      background-color: #efefef;
      border-radius: 4px;
      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .left {
          span {
            font-size: 12px;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
